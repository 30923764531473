import * as actionCreators from '../actionTypes';

const userViewSettings = {
  view_invoices: true,
  view_all_accounting: true,
  view_subscriptions: true,
  view_customer_accounting: true,
  view_all_inventory: true,
  view_consolidated_invoices: true,
  view_customers: true,
  view_estimates: true,
  view_products: true,
  view_service_calls: true,
  view_transfer_orders: true,
  view_warehouses: true,
  view_dispatch_calendar: true,
  view_unsync_system: true,
  view_all_systems_actions: true,
  view_test_system: true,
  view_oos_system: true,
};

const initialState = {
  userList: [],
  listloading: false,
  listerror: null,
  currentUser: null,
  users: [],
  deleteMessage: null,
  isDelete: false,
  submitMessage: null,
  isSubmitted: false,
  assignedClientList: null,
  assignedClientListLoading: false,
  assignedClientListError: null,
  userRolesList: null,
  userRolesListLoading: false,
  userRolesListError: null,
  userEditData: null,
  userEditDataLoading: false,
  userEditDataError: null,
  userViewSettings: userViewSettings,
  is_permissions_set: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case actionCreators.GET_CURRENT_USER:
      return {
        ...state,
        currentUser: state.userList.filter(user => {
          let current;
          if (user.id === action.payload) {
            current = user;
          }
          return current;
        })
      };

    case actionCreators.USER_LIST_CHECK:
      return {
        ...state,
        listloading: true,
        isDelete: false,
      };
    case actionCreators.USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        listloading: false,
        isDelete: false,
      };

    case actionCreators.USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isDelete: false,
      };
    case actionCreators.USER_LIST_FAIL:
      return {
        ...state,
        listerror: action.payload?.request?.statusText ?? null,
        listloading: false,
        isDelete: false,
      };

    case actionCreators.USER_EDIT_CHECK:
      return {
        ...state,
        userEditDataLoading: true,
        isDelete: false,
      };
    case actionCreators.USER_EDIT_SUCCESS:
      return {
        ...state,
        userEditData: action.payload,
        userEditDataLoading: false,
        isDelete: false,
      };
    case actionCreators.USER_EDIT_FAIL:
      return {
        ...state,
        userEditDataError: action.payload?.request?.statusText ?? null,
        userEditDataLoading: false,
        isDelete: false,
      };

    case actionCreators.USER_VIEW_SETTINGS_SUCCESS:
      return {
        ...state,
        userViewSettings:action.payload.allowed_actions,
        is_permissions_set: action.payload.is_permissions_set,
      }

    case actionCreators.GET_USER_VIEW_SETTINGS:
    case actionCreators.SET_USER_VIEW_SETTINGS_FAIL:
      return {
        ...state,
        userViewSettings:userViewSettings,
        is_permissions_set: false,
      };

    // User update
    case actionCreators.USER_UPDATE_CHECK:
      return {
        ...state,
        isSubmitted: false,
      };
    case actionCreators.USER_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload,
      };
    case actionCreators.USER_UPDATE_FAIL:
      return {
        ...state,
        isSubmitted: false,
      };

    // Delete user
    case actionCreators.USER_DELETE_CHECK:
      return {
        ...state,
        isDelete: false,
      };
    case actionCreators.USER_DELETE_SUCCESS:
      return {
        ...state,
        isDelete: true,
        deleteMessage: action.payload,
      };
    case actionCreators.USER_DELETE_FAIL:
      return {
        ...state,
        isDelete: false,
      };

    // Assigned clients
    case actionCreators.ASSIGNEDCLIENT_LIST_CHECK:
      return {
        ...state,
        assignedClientListLoading: true,
      };
    case actionCreators.ASSIGNEDCLIENT_LIST_SUCCESS:
      return {
        ...state,
        assignedClientList: action.payload,
        assignedClientListLoading: false,
      };
    case actionCreators.ASSIGNEDCLIENT_LIST_FAIL:
      return {
        ...state,
        assignedClientListError: action.payload?.request?.statusText ?? null,
        assignedClientListLoading: false,
      };

    // User roles
    case actionCreators.USERROLES_LIST_CHECK:
      return {
        ...state,
        userRolesListLoading: true,
      };
    case actionCreators.USERROLES_LIST_SUCCESS:
      return {
        ...state,
        userRolesList: action.payload,
        userRolesListLoading: false,
      };
    case actionCreators.USERROLES_LIST_FAIL:
      return {
        ...state,
        userRolesListError: action.payload?.request?.statusText ?? null,
        userRolesListLoading: false,
      };

    default:
      return { ...state };
  }
};

export default userReducer;
