import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: ""
    };
  }
  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
    });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div style={{ background: '#ECECEC', padding: '30px' }}>
          <div title="Sorry Something went wrong!!!" style={{width: 300}}>
            <h1>Sorry Something went wrong!!!</h1>
            {this.props.nameOfErrorComponent ? (
              <>
                <pre>Error with {this.props.nameOfErrorComponent}</pre>
              </>
            ) : null}
            <pre>{this.state.errorMessage}</pre>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
